<template>
    <div class="flex flex-col flex-grow justify-end">
        <div class="flex mx-2 items-center justify-center" v-if="isVoiceInput">
            <span class="text-gray-600 mt-2 mx-2 text-sm text-center leading-relaxed tracking-wide">To test a voice application, please install the Android <a href="https://play.google.com/store/apps/details?id=com.africastalking.sandbox&hl=en" target="_blank" class="text-orange-500  hover:text-orange-700 underline">simulator</a>. Alternatively, <a class="text-orange-500  hover:text-orange-700 underline" href="mailto:voice@africastalking.com?subject=Requesting For Test Number" target="_blank">request</a> a number for live testing.</span>
        </div>
        <div class="phone h-16 over shadow-inner">
            <input
                ref="phoneInput"
                type="text"
                class="h-full w-full outline-none text-2xl px-4 text-center text-gray-800"
                v-model.trim="dialNumber" />
        </div>
        <div class="grid grid-cols-3 border-t border-gray-400 bg-white row-gap-3 grid-rows-3 py-4">
            <div
                v-for="(number, idx) in phonePad"
                :key="idx"
                class="flex justify-center items-center">
                <div
                    @click="onPhoneKey(number)"
                    class="flex flex-col justify-center items-center h-12 w-12 rounded-full py-3 cursor-pointer">
                    <span
                        :class="{
                            [/^\d/.test(number.key) ? 'text-blue-600' : 'text-gray-600']: true,
                        }"
                        class="text-2xl">{{ number.key }}</span>
                    <span v-if="number.alternate" class="text-xs text-gray-500 uppercase">{{ number.alternate }}</span>
                </div>
            </div>
            <div class="col-span-3 py-3 flex justify-center items-center">
                <div
                    class="h-12 w-12 rounded-full shadow-md justify-center items-center bg-green-600 flex text-white cursor-pointer hover:bg-green-500 transition duration-150 ease-in-out"
                    @click="onCall">
                    <svg class="h-6 pointer-events-none fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PhonePad',

    props: {
        dialPhoneNumber: {
            type: Function,
            default: () => () => {},
        },

        dialUssdCode: {
            type: Function,
            default: () => () => {},
        },
    },

    data() {
        return {
            phonePad: [{
                key: '1',
                alternate: '~',
            }, {
                key: '2',
                alternate: 'abc',
            }, {
                key: '3',
                alternate: 'def',
            }, {
                key: '4',
                alternate: 'ghi',
            }, {
                key: '5',
                alternate: 'jkl',
            }, {
                key: '6',
                alternate: 'mno',
            }, {
                key: '7',
                alternate: 'pqrs',
            }, {
                key: '8',
                alternate: 'tuv',
            }, {
                key: '9',
                alternate: 'wxyz',
            }, {
                key: '*',
            }, {
                key: '0',
                alternate: '+',
            }, {
                key: '#',
            }],

            dialNumber: '',
            isVoiceInput: false,
        };
    },

    mounted() {
        this.$refs.phoneInput.focus();
    },

    watch: {
        dialNumber(value) {
            if (!value || value === '') {
                this.isVoiceInput = false;
            }
        },
    },

    methods: {
        onPhoneKey(number) {
            this.dialNumber = `${this.dialNumber}${number.key}`;
        },

        onPhoneAlternate(number) {
            if (number.key === '0') {
                this.dialNumber = `${this.dialNumber}${number.alternate}`;
            }
        },

        onCall() {
            if (/^(\*\d{3,5}#|\*\d{3,5}\*(\d+|\d+(\*\d+)*)#)$/.test(this.dialNumber)) {
                this.dialUssdCode(this.dialNumber);
            } else {
                this.isVoiceInput = /^\d{3,}$/.test(this.dialNumber);
                if (!this.isVoiceInput) {
                    console.error('Invalid phone number or USSD code');
                }
            }
        },
    },
};
</script>
